import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import './Main.css';

function Navi() {
    const REST_API_KEY = "77a588b1d637314c2444080fa23aa56e";
    const REDIRECT_URI = "https://www.hdeng.net/kakao";
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

    return (
        <Navbar bg="light" variant="light">
            <Container>
                <Navbar.Brand href="/" className='brand'>
                    <img className="phoneImage" alt="iPhone_01" src="https://hdeng13.speedgabia.com/common/logo500px.png" />
                    {/* <Image className='img-product' src="img/logo500px.png" /> */}
                </Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link href="company">회사소개</Nav.Link>
                    <NavDropdown title="농업용정수기" id="basic-nav-dropdown" renderMenuOnMount={true}>
                        <NavDropdown.Item href="/hdro">제품 정보</NavDropdown.Item>
                        <NavDropdown.Item href="/hdro/case">제품 적용 사례</NavDropdown.Item>
                        <NavDropdown.Item href="/hdro/menual">사용설명서</NavDropdown.Item>
                        <NavDropdown.Item href="/hdro/data">자료실</NavDropdown.Item>
                        <NavDropdown.Item href={KAKAO_AUTH_URL}>견적요청</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="고객지원" id="basic-nav-dropdown" renderMenuOnMount={true}>
                        <NavDropdown.Item href="/notice">공지사항</NavDropdown.Item>
                        {/* <NavDropdown.Item href="/estimate">견적문의</NavDropdown.Item> */}
                        {/* <NavDropdown.Item href="/as">A/S 신청</NavDropdown.Item> */}
                    </NavDropdown>
                </Nav>
            </Container>
        </Navbar>
    );
}

export default Navi;

