import React from "react"
import './Footer.css';


const Footer = () => <footer className="page-footer font-small blue pt-4">
    <div className="container-fluid text-center text-md-left">
        <div className="row">
            <div className="col-md-6 mt-md-0 mt-3 footer-main">
                <h5 className="text-uppercase">한들이엔지(주)</h5>
                <div className="footer-text">
                    <p>대표이사 : 김주환</p>
                    <p>사업자등록번호 : 143-81-11889</p>
                    <p>주소 : 경기도 안산시 단원구 산단로 325, F524호</p>
                    <p>전화 : 031-356-3752 | 팩스 : 02-6918-6504</p>
                    <p>이메일 : info@hdeng.net</p>
                    {/* <p>개인정보관리책임자 : 홍길동</p> */}
                </div>
            </div>

            <hr className="clearfix w-100 d-md-none pb-0" />

            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase">Links</h5>
                <ul className="list-unstyled">
                    <li><a href="https://blog.naver.com/kjh0772">공식 네이버 블로그</a></li>
                    <li><a href="https://www.youtube.com/@hdeng1946">공식 유튜브</a></li>
                    <li><a href="#!">모니터링 시스템</a></li>
                    <li><a href="https://smartstore.naver.com/dicam">네이버 스마트 스토어</a></li>
                </ul>
            </div>

            {/* <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase">Links</h5>
                <ul className="list-unstyled">
                    <li><a href="#!">Link 1</a></li>
                    <li><a href="#!">Link 2</a></li>
                    <li><a href="#!">Link 3</a></li>
                    <li><a href="#!">Link 4</a></li>
                </ul>
            </div> */}
        </div>
    </div>

    <div className="footer-copyright text-center py-3">© 2020 Copyright HDENG Co., Ltd. All rights reserved.
    </div>
</footer>

export default Footer