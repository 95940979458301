import React from 'react';
import './Main.css';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
// import KakaoLogin from '../KakaoApi.js';


function Main() {
    return (
        <Container>
            <Row>
                {/* <MainCarousel /> */}
                <MainItem />
            </Row>
            <Row>
                {/* <Notice /> */}
            </Row>
            {/* <Row>
                <Col sm={12} md={6}><MainLink /></Col>
                <Col sm={12} md={6}><MainLink /></Col>
            </Row>
            <Row>
                <Col sm={12} md={6}><MainLink /></Col>
                <Col sm={12} md={6}><MainLink /></Col>
            </Row> */}
        </Container>
    );
}
function MainItem() {
    const REST_API_KEY = "77a588b1d637314c2444080fa23aa56e";
    const REDIRECT_URI = "https://www.hdeng.net/kakao";
    // const REDIRECT_URI = "https://0772.iptime.org:3000/kakao";
    // const REDIRECT_URI = "http://localhost:3000/oauth/kakao/callback";
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

    return (
        <Container className='container-item'>
            <Card>
                <Card.Img variant="top" src="https://hdeng13.speedgabia.com/main/main_item1.png" />
                <Card.Body>
                    <Card.Text>
                        이젠 안심할 수 없는 지하수, 역삼투압 정수 시스템으로 안전하고 향상된 품질을 경험해 보세요.
                    </Card.Text>
                    <Button variant="primary" href="/hdro" style={{ marginRight: '10px' }}>제품 정보 바로가기</Button>
                    <Button variant="primary" href={KAKAO_AUTH_URL}>온라인 견적하기</Button>
                    {/* {' '}<Button variant="primary" href="/estimate">온라인 견적 테스트</Button> */}
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src="https://hdeng13.speedgabia.com/main/main_item2.png" />
                <Card.Body>
                    <Card.Text>
                        재배 생산의 필수! 스마트한 양액 공급 시스템을 이용해 보세요.
                    </Card.Text>
                    {/* <Button variant="primary" href="/hdro">제품 정보 바로가기</Button> */}
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src="https://hdeng13.speedgabia.com/main/main_item3.png" />
                <Card.Body>
                    <Card.Text>
                        더욱 자세한 내용이 필요하신 경우 연락 주세요! TEL 031-356-3752
                    </Card.Text>
                    {/* <Button variant="primary" href="/hdro">제품 정보 바로가기</Button> */}
                </Card.Body>
            </Card>
        </Container>
    )
}
function Notice() {
    return (
        <Card className='main-notice'>
            <Card.Header>공지사항</Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item>Cras justo odio</ListGroup.Item>
                <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
            </ListGroup>
        </Card>
    );
}
function MainLink() {
    return (
        <Button variant="light" href="/hdro" className='link-button'>
            <Container className='link-main'>
                <Row>
                    <Col xs={2}><Image src="https://hdeng13.speedgabia.com/hdro/model/hdro801/bc-hdro801.png" /></Col>
                    <Col xs={8}>농업용 정수기</Col>
                    <Col xs={2}><Image src="./img/icon_click.png" /></Col>
                </Row>
            </Container>
        </Button>
    )
}

function MainCarousel() {
    return (
        <Carousel className='carousel-main'>
            <Carousel.Item interval={1000}>
                <img
                    className="d-block w-100"
                    src="/img/mainbg.jpg"
                    //url(/img/mainbg.jpg);
                    alt="First slide"
                />
                <Carousel.Caption className='carousel-h3'>
                    <h3>First slide label</h3>
                    <p>캐러셀</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={500}>
                <img
                    className="d-block w-100"
                    src="/img/mainbg.jpg"
                    alt="Second slide"
                />
                <Carousel.Caption className='carousel-h3'>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/img/mainbg.jpg"
                    alt="Third slide"
                />
                <Carousel.Caption className='carousel-h3'>
                    <h3>Third slide label</h3>
                    <p>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}

export default Main;

//copy1 한들이엔지(주)에서는<br />최고의 솔루션을 제공하기 위해 노력하겠습니다.</p>