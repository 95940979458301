import React from "react";

export default function Page404() {
    return (
        <div style={{ margin: '50px', width: '100vw', height: '30vh' }}>
            <h1>404</h1>
            <h5>Page not found</h5>
        </div>
    )
}
