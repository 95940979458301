import React from "react"
import Map from './MapContainer.js'
import './Company.css';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function Company() {
    return (
        <Container>
            <Row className='intro'>
                <div className='intro-copy'>
                    <h4>한들이엔지(주)는<br />
                        더 나은 솔루션을 제공합니다.</h4>
                </div>
                <p></p><br />
                <h5>사업분야</h5>
                <ul>
                    <li>수처리 기술을 이용해 정수 시스템을 공급합니다.</li>
                    <li>농업 관수 시설에 필요한 양액공급기 및 부자재 생산</li>
                    <li>농업 환경 제어에 필요한 무인방제 시스템 생산</li>
                    <li>데이터 기반 온도, 습도, Co2 센서 모듈 생산</li>
                    <li>농업 및 산업 현장에 필요한 제어 시스템을 공급합니다.</li>
                </ul>
                <h5>기술현황</h5>
                <ul>
                    <li>제어, 관제 시스템 설계 및 제조</li>
                    <li>터치컨트롤러 + PLC 기반 제어 시스템 구축</li>
                    <li>제어를 위한 전기, 전자회로 구축</li>
                    <li>금속 가공 및 설계 (레이저 가공, 절곡, 용접, 도장)</li>
                    <li>배관 설계 및 제조</li>
                </ul>
                <h5>오시는 길</h5>
                <p>연락처 : 031-356-3752</p>
                <p>주소 : 경기도 안산시 단원구 산단로 325, 리드스마트스퀘어 F524호</p>
                <div className="kakaomap">
                    <Map />
                </div>
            </Row>
        </Container>
    )
}

export default Company;

