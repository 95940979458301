import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import './Product.css';
import { useSelector } from 'react-redux';

function Product() {
    return (
        <div>
            <Container fluid="lg">
                <Row>
                    <Col sm={12} md={6} xl={8}>
                        <div>
                            <h1>준비중입니다.</h1>
                        </div></Col >
                    <Col>{productList()}</Col>
                </Row>
            </Container>
        </div>
    )
}

function productList() {

    return (
        <div>
            <h2>농업용수 처리기</h2>
            <ListGroup>
                <ListGroup.Item>HDRO-401 (5톤/일)</ListGroup.Item>
                <ListGroup.Item>HDRO-402 (10톤/일)</ListGroup.Item>
                <ListGroup.Item>HDRO-801 (20톤/일)</ListGroup.Item>
                <ListGroup.Item>HDRO-802 (40톤/일)</ListGroup.Item>
                <ListGroup.Item>HDRO-803 (60톤/일)</ListGroup.Item>
            </ListGroup>
        </div>
    )
}

export default Product;