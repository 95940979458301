import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from "react-redux"
import './Estimate.css';
import makePdf from '../make_pdf';
import Estimate2 from './Estimate2';
import axios from 'axios';
// import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
// import styled from "styled-components";

//이카운트 API 연계 https://mins-father.tistory.com/27
//[React] navigate로 컴포넌트 이동시 props 전달 방법 https://9x211x2.tistory.com/22

export default function Estimate() {
    let [modal, setModal] = useState(false);
    // Redux
    let x = useSelector((state) => state.hdroModel)
    let x2 = useSelector((state) => state.hdroPreFilter)
    //let x3 = useSelector((state) => state.customer)
    // 견적 Item
    const [mainItem, setMainitem] = useState(2);
    const handleChange2 = (val) => setMainitem(val);

    const [subItem, setSubItem] = useState([0]);
    const handleChange = (val) => setSubItem(val);
    const [privacy, setPrivacy] = useState(false);
    // Customer information
    const [customer, setCustomer] = useState(
        {
            request_time: '',
            name: '',
            phone: '',
            memo: '',
            main_item: '',
            sub_item: '',
            main_i2: 1,
            sub_i2: 1,
            total_price: ''
        }
    )

    const pdf = makePdf()
    const navigate = useNavigate();
    const onClick = async (e) => {

        var phone_number = customer.phone;
        let regExp = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
        let result = regExp.test(phone_number);
        console.log(result);

        var test_name = customer.name;
        var errorMsg = '';
        if (!privacy) {
            errorMsg = "개인정보 수집 및 이용에 동의해 주세요.";
        }
        else if (test_name.length == 0) {
            errorMsg = "성함을 입력해 주세요.";
        }
        else if (!result) {
            errorMsg = "잘못된 번호입니다. 다시 입력해 주십시오.";
        }

        if (!errorMsg) {
            console.log("견적 요청 성공")
            alert("견적을 요청해 주셔서 감사합니다.")
            customerSetData()
            // setModal(true)
            // e.preventDefault()
            // await pdf.viewWithPdf()
        }
        else {
            console.log("견적 요청 실패")
            alert(errorMsg)
        }
    }

    function fetch_unix_timestamp() {
        return Math.floor(new Date().getTime() / 1000);
    }

    function customerSetData() {
        var subItemList = new Array();
        let subPrice = 0;
        for (let i = 0; i < subItem.length; i++) {
            subItemList.push(x2[subItem[i]].name)
            subPrice += x2[subItem[i]].price
        }
        let timestamp = fetch_unix_timestamp();
        const totalPrice = (x[mainItem].price + subPrice).toLocaleString()

        let newCustomer = {
            request_time: timestamp,
            name: '',
            phone: '',
            memo: '',
            main_item: x[mainItem].name,
            sub_item: JSON.stringify(subItemList),
            total_price: totalPrice,
            main: mainItem,
            sub: subItem
        };
        newCustomer.name = customer.name;
        newCustomer.phone = customer.phone;
        newCustomer.memo = customer.memo;

        const localurl = "http://api.hdeng.net/api/estimate";
        axios.post(localurl, newCustomer)

        navigate("/estimate2", { state: newCustomer });
        //로컬에서 CORS policy 관련 에러가 발생하는 이유
        //https://velog.io/@takeknowledge/%EB%A1%9C%EC%BB%AC%EC%97%90%EC%84%9C-CORS-policy-%EA%B4%80%EB%A0%A8-%EC%97%90%EB%9F%AC%EA%B0%80-%EB%B0%9C%EC%83%9D%ED%95%98%EB%8A%94-%EC%9D%B4%EC%9C%A0-3gk4gyhreu
    }
    //get요청하기 https://limjunho.github.io/2020/08/11/Node.js-client-request-receive.html

    return (
        <Container>
            <Row>
                <Col sm={12} md={7}>
                    <Row>
                        <Image className='img-model' src="https://hdeng13.speedgabia.com/hdro/model/hdro801/bc-hdro801.png" rounded />
                    </Row>
                    <Row style={{ textAlign: 'center' }}>
                        <h3>농업용 정수기</h3>
                        <p></p>
                        <h4>모델 : {x[mainItem].name}</h4>
                        {/* 모델별 사양 표기 */}
                        <Row>
                            <Col>생산량</Col>
                            <Col>멤브레인</Col>
                            <Col>펌프용량</Col>
                            <hr />
                        </Row>
                        <Row>
                            <Col>{x[mainItem].capacity} m³/시간<br />
                                {x[mainItem].capacity * 20} m³/하루</Col>
                            <Col>{x[mainItem].filter}<br />
                                {x[mainItem].filterQty} 개</Col>
                            <Col>{x[mainItem].pump} kW<br />
                                {(x[mainItem].pump / 0.75).toFixed(2).toLocaleString()} 마력</Col>
                        </Row>
                    </Row>
                </Col>
                <Col className='main-menu'>

                    <Row>
                        <h5>정수 용량별 모델 (역삼투압)</h5>

                        <ToggleButtonGroup type="radio" name="options" value={mainItem} onChange={handleChange2} vertical={true} defaultValue={2}>
                            {x.map((radio, idx) => (
                                <ToggleButton
                                    id={`radio-${idx}`}
                                    variant="outline-primary"
                                    value={idx}
                                    key={idx}
                                >
                                    <p id={`radio2-${idx}`}>{radio.name} 정수량  {radio.capacity}톤/시간</p>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Row>
                    <Row>
                        <h5>추가 설비 (전처리, 펌프)</h5>
                        <p>* 샌드필터 설치는 멤브레인 필터 수명에 큰 도움을 주므로 권장합니다.</p>
                        <ToggleButtonGroup type="checkbox" value={subItem} onChange={handleChange} vertical={true}>
                            {x2.map((check, idx) => (
                                <ToggleButton
                                    id={`check-${idx}`}
                                    variant="outline-primary"
                                    value={idx}
                                    key={idx}
                                >
                                    <p id={`check2-${idx}`}>{check.name} ({check.filter} / 배관 : {check.capacity}A)</p>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Row>
                    <Row>
                        <h5>안내사항</h5>
                        <ul>
                            <li>모의 견적 이므로 수질, 현장 상황에 따라 견적이 달라질 수 있습니다.</li>
                            <li>해수, 고염도 정수는 별도 견적 요청해 주세요.</li>
                            <li>수집/이용 목적 : 상담 및 문의 등 고객요청 처리 및 결과 회신</li>
                            <li>수집하는 항목 : 이름,연락처,주소,이메일</li>
                            <li>보유 / 이용 기간 : 고객요청 처리 후 3개월</li>
                            <li>동의를 거부할 수 있으며, 거부시 이용이 제한될 수 있습니다.</li>
                        </ul>
                        <Form.Check
                            type="checkbox"
                            id="custom-checkbox"
                            style={{ textAlign: 'left' }}
                            label=" 개인정보 수집 및 이용에 동의"
                            onChange={(e) => {
                                if ({ privacy } == true) {
                                    setPrivacy(false)
                                }
                                else { setPrivacy(true) }
                            }}
                        />
                    </Row>
                    <Row>
                        <h5>고객 정보</h5>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="cname" value={customer.name}>
                                성 ㅤ 함
                            </InputGroup.Text>
                            <Form.Control
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                placeholder="고객 이름 또는 기관"
                                // onChange={(e) => setCustomer(e.target.value)}
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    name: e.target.value
                                })}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputPhone" value={customer.phone}>
                                연 락 처
                            </InputGroup.Text>
                            <Form.Control
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                placeholder="핸드폰 번호"
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    phone: e.target.value
                                })}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text id="input-memo" value={customer.memo}>
                                특이사항
                            </InputGroup.Text>
                            <Form.Control
                                as="textarea"
                                aria-label="With textarea"
                                placeholder="ex) 수질 상태, 정수 목적, 요구사항"
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    memo: e.target.value
                                })}
                            />
                        </InputGroup>
                        <p></p>
                        <Button variant="primary" onClick={onClick}>견적 요청</Button>
                        {/* <Button variant="primary" onClick={onClick2}>테스트하기</Button> */}
                    </Row>
                </Col>
            </Row>
            {/* <Row>
                {
                    modal == true ?
                        <Estimate2
                            customer={customer}
                            mainItem={mainItem}
                            subItem={subItem}
                        /> : null
                }
            </Row> */}
        </Container>
    )
}