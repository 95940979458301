/*global kakao */
import React, { useEffect } from "react";

export default function Map() {
    useEffect(() => {
        mapscript();
    }, []);

    const mapscript = () => {
        let container = document.getElementById("map");
        let options = {
            center: new kakao.maps.LatLng(37.32340930216372, 126.78508307745506),
            level: 4,
        };
        //map
        const map = new kakao.maps.Map(container, options);

        //마커가 표시 될 위치
        let markerPosition = new kakao.maps.LatLng(
            37.32340930216372,
            126.78508307745506
        );

        // 마커를 생성
        let marker = new kakao.maps.Marker({
            position: markerPosition,
        });

        // 마커를 지도 위에 표시
        marker.setMap(map);
    };

    return <div id="map" style={{ width: "100vw", height: "50vw" }}></div>;
}

//블로그 https://velog.io/@bearsjelly/React-kakao-%EC%A7%80%EB%8F%84-%EB%9D%84%EC%9A%B0%EA%B8%B0-3-%EB%A7%88%EC%BB%A4%EB%9D%84%EC%9A%B0%EA%B8%B0