import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './Case.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';

function Case() {
    return (
        <CaseDetail />
    )
}
export default Case;

function CaseImg() {
    return (
        <div className='card-img'>
            <Image src="https://hdeng13.speedgabia.com/hdro/model/hdro801/bc-hdro801.png" />
        </div>
    )
}
function CaseDetail() {
    let x = useSelector((state) => state.hdroCase)

    // const [index, setIndex] = useState(0);
    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    // x.event
    const [index, setIndex] = useState([]);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Container>
            {x.map((a, i) =>
                <Card className='card-detail' key={i}>
                    <Card.Header>Item No.{x[i].id}</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={8}>
                                <Carousel activeIndex={index[i]} onSelect={handleSelect}>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={x[i].img1}
                                            alt="First slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={x[i].img2}
                                            alt="Second slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={x[i].img3}
                                            alt="Third slide"
                                        />
                                    </Carousel.Item>
                                </Carousel>
                            </Col>
                            <Col sm={12} md={4}>
                                <Card.Title>Model : {x[i].model}</Card.Title>
                                <Card.Text>
                                    <ul>
                                        <li>생산량 : {x[i].capacity}</li>
                                        <li>설치일 : {x[i].date}</li>
                                        <li>설치장소 : {x[i].location}</li>
                                        <li>적용목적 : {x[i].purpose}</li>
                                        <li>기대효과 : {x[i].memo}</li>
                                    </ul>
                                </Card.Text>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            )}
        </Container>

    )
}
