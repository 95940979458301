import { configureStore, createSlice } from '@reduxjs/toolkit'

let user = createSlice({
    name: '텔레토비',
    initialState: ['뚜비', '나나', '뽀']
})
// 정수기 모델 정보
let hdroModel = createSlice({
    name: 'hdroModel',
    initialState: [
        {
            name: "HDRO-401",
            capacity: 0.25,
            electric: "220V / 60Hz",
            pump: 1.3,
            filter: "4040",
            filterQty: 1,
            size: "1300x800x1750",
            value: 0,
            img: "https://hdeng13.speedgabia.com/common/noImg_M.png",
            price: 9000000
        },
        {
            name: "HDRO-402",
            capacity: 0.5,
            electric: "220V / 60Hz",
            pump: 1.3,
            filter: "4040",
            filterQty: 2,
            size: "1300x800x1750",
            value: 0,
            img: "https://hdeng13.speedgabia.com/hdro/model/hdro402/20210916_181048.jpg",
            price: 10000000
        },
        {
            name: "HDRO-801",
            capacity: 1,
            electric: "220V / 60Hz",
            pump: 1.3,
            filter: "8040",
            filterQty: 1,
            size: "1800x800x1750",
            value: 0,
            img: "https://hdeng13.speedgabia.com/hdro/model/hdro801/bc-hdro801.png",
            price: 12000000
        }
        ,
        {
            name: "HDRO-802",
            capacity: 2,
            electric: "380V / 60Hz",
            pump: 3,
            filter: "8040",
            filterQty: 2,
            size: "1800x800x1750",
            value: 0,
            img: "https://hdeng13.speedgabia.com/common/noImg_M.png",
            price: 15000000
        }
        ,
        {
            name: "HDRO-803",
            capacity: 3,
            electric: "380V / 60Hz",
            pump: 4,
            filter: "8040",
            filterQty: 3,
            size: "1800x800x1750",
            value: 0,
            img: "https://hdeng13.speedgabia.com/hdro/model/hdro803/20221130_143830.jpg",
            price: 18000000
        }
    ]
})
// 정수기 전처리 관련
let hdroPreFilter = createSlice({
    name: 'hdroPreFilter',
    initialState: [
        {
            name: "샌드필터",
            capacity: 50,
            filter: "여과사",
            Volume: 148,
            size: "370x1670",
            value: 0,
            img: "https://hdeng13.speedgabia.com/common/noImg_M.png",
            price: 3000000
        },
        {
            name: "제철제망간",
            capacity: 50,
            filter: "Birm",
            Volume: 148,
            size: "370x1670",
            value: 0,
            img: "https://hdeng13.speedgabia.com/common/noImg_M.png",
            price: 3000000
        },
        {
            name: "활성탄",
            capacity: 50,
            filter: "활성탄",
            Volume: 148,
            size: "370x1670",
            value: 0,
            img: "https://hdeng13.speedgabia.com/common/noImg_M.png",
            price: 3000000
        }, {
            name: "연수기",
            capacity: 50,
            filter: "이온수지",
            Volume: 148,
            size: "370x1670",
            value: 0,
            img: "https://hdeng13.speedgabia.com/common/noImg_M.png",
            price: 3000000
        },
        {
            name: "자동펌프",
            capacity: 25,
            filter: "1마력",
            Volume: 1,
            size: "370x250",
            value: 0,
            img: "https://hdeng13.speedgabia.com/common/noImg_M.png",
            price: 500000
        }
    ]
})
// 정수기 설치 사례
let hdroCase = createSlice({
    name: 'hdroCase',
    initialState: [
        {
            id: "0",
            event: "0",
            model: "HDRO-801",
            capacity: "시간당 1톤",
            date: "2022-09-07",
            location: "강원도 강릉시",
            purpose: "농업용수, 양액재배",
            memo: "중탄산, 철분 제거",
            img1: "https://hdeng13.speedgabia.com/hdro/case/00/20220907_115820.jpg",
            img2: "https://hdeng13.speedgabia.com/hdro/case/00/20220907_115820.jpg",
            img3: "https://hdeng13.speedgabia.com/hdro/case/00/20220907_115820.jpg"
        },
        {
            id: "1",
            event: "0",
            model: "HDRO-801",
            capacity: "시간당 1톤",
            date: "2022-09-07",
            location: "강원도 강릉시",
            purpose: "농업용수, 양액재배",
            memo: "중탄산, 철분 제거",
            img1: "https://hdeng13.speedgabia.com/hdro/case/01/20220907_143336.jpg",
            img2: "https://hdeng13.speedgabia.com/hdro/case/01/20220907_143343.jpg",
            img3: "https://hdeng13.speedgabia.com/hdro/case/01/20220907_143343.jpg"
        },
        {
            id: "2",
            event: "0",
            model: "HDRO-801",
            capacity: "시간당 1톤",
            date: "2022-05-21",
            location: "충청남도 공주시",
            purpose: "농업용수, 양액재배",
            memo: "중탄산 제거",
            img1: "https://hdeng13.speedgabia.com/hdro/case/02/20220521_175759.jpg",
            img2: "https://hdeng13.speedgabia.com/hdro/case/02/20220521_175759.jpg",
            img3: "https://hdeng13.speedgabia.com/hdro/case/02/20220521_175759.jpg"

        },
        {
            id: "3",
            event: "0",
            model: "HDRO-801",
            capacity: "시간당 1톤",
            date: "2022-06-03",
            location: "충청남도 공주시",
            purpose: "농업용수, 양액재배",
            memo: "중탄산 제거",
            img1: "https://hdeng13.speedgabia.com/hdro/case/03/20220603_153216.jpg",
            img2: "https://hdeng13.speedgabia.com/hdro/case/03/20220603_153247.jpg",
            img3: "https://hdeng13.speedgabia.com/hdro/case/03/20220603_130911.jpg"
        },
        {
            id: "4",
            event: "0",
            model: "HDRO-801H",
            capacity: "시간당 1.2톤",
            date: "2022-06-04",
            location: "경기도 화성시",
            purpose: "농업용수, 양액재배",
            memo: "염분(NaCl) 제거",
            img1: "https://hdeng13.speedgabia.com/hdro/case/04/20220604_133030.jpg",
            img2: "https://hdeng13.speedgabia.com/hdro/case/04/20220604_133022.jpg",
            img3: "https://hdeng13.speedgabia.com/hdro/case/04/20220604_133038.jpg"
        },
        {
            id: "5",
            event: "0",
            model: "HDRO-801",
            capacity: "시간당 1톤",
            date: "2022-08-20",
            location: "경상북도 의성군",
            purpose: "농업용수, 양액재배",
            memo: "중탄산, 철분, 침전물 제거",
            img1: "https://hdeng13.speedgabia.com/hdro/case/05/20220820_164601.jpg",
            img2: "https://hdeng13.speedgabia.com/hdro/case/06/20220822_143310.jpg",
            img3: "https://hdeng13.speedgabia.com/hdro/case/05/20220820_164601.jpg"
        },
        {
            id: "6",
            event: "0",
            model: "HDRO-402",
            capacity: "시간당 0.5톤",
            date: "2022-09-15",
            location: "대구광역시",
            purpose: "농업용수, 양액재배",
            memo: "중탄산 제거",
            img1: "https://hdeng13.speedgabia.com/hdro/case/07/20220915_162552.jpg",
            img2: "https://hdeng13.speedgabia.com/hdro/case/07/20220915_162609.jpg",
            img3: "https://hdeng13.speedgabia.com/hdro/case/07/20220915_162616.jpg"
        },
        {
            id: "7",
            event: "0",
            model: "HDSRO-402",
            capacity: "시간당 0.25톤",
            date: "2022-07-18",
            location: "경기도 화성시",
            purpose: "농업용수, 과수",
            memo: "간척지 지하수 염분 제거 해수용",
            img1: "https://hdeng13.speedgabia.com/hdro/case/08/20220718_155933.jpg",
            img2: "https://hdeng13.speedgabia.com/hdro/case/08/20220718_160007.jpg",
            img3: "https://hdeng13.speedgabia.com/hdro/case/08/20220718_160117.jpg"
        },
        {
            id: "8",
            event: "0",
            model: "HDRO-801",
            capacity: "시간당 1톤",
            date: "2022-12-30",
            location: "경상북도 영천시",
            purpose: "농업용수, 양액재배",
            memo: "중탄산, 철분, 염분, 경도 제거",
            img1: "https://hdeng13.speedgabia.com/hdro/case/09/20221230_122138.jpg",
            img2: "https://hdeng13.speedgabia.com/hdro/case/09/20221230_122206.jpg",
            img3: "https://hdeng13.speedgabia.com/hdro/case/09/20221230_122217.jpg"
        },
        {
            id: "9",
            event: "0",
            model: "HDRO-801",
            capacity: "시간당 1톤",
            date: "2022-08-17",
            location: "경상북도 의성시",
            purpose: "농업용수, 양액재배",
            memo: "중탄산, 경도 제거",
            img1: "https://hdeng13.speedgabia.com/hdro/case/11/20220817_165440.jpg",
            img2: "https://hdeng13.speedgabia.com/hdro/case/11/20220817_165433.jpg",
            img3: "https://hdeng13.speedgabia.com/hdro/case/11/20220817_165415.jpg"
        },
    ]
})

export default configureStore({
    reducer: {
        user: user.reducer,
        hdroModel: hdroModel.reducer,
        hdroPreFilter: hdroPreFilter.reducer,
        hdroCase: hdroCase.reducer
    }
})

