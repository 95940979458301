import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Notice.css';

export default function Notice() {
    return (
        <Container className='intro'>
            {/* <MqttSubscribe /> */}
            <Row>
                <div className='intro-copy'>
                    <h4>공지사항</h4>
                </div>
                <hr />
            </Row>
            <Row className='item'>
                <p>No.01 Date : 2023-02-06</p>
                <h5> 한들이엔지 홈페이지를 새롭게 단장하였습니다.</h5>
                <p>안녕하세요. 한들이엔지 대표 김주환입니다.</p>
                <p>새해를 맞이하여 한들이엔지 홈페이지를 새단장 하였습니다.</p>
                <p>더 나은 서비스와 고객 만족을 위해 노력하겠습니다.</p>
                <p>감사합니다.</p>
                <hr />
                <p>No.02 Date : 2023-02-06</p>
                <h5>기타 제품 문의</h5>
                <p>농업용 정수기 외 기타 제품은 별도 문의를 주시면 안내해 드리겠습니다.</p>
                <p>취급하는 제품은 다음과 같습니다.</p>
                <ul>
                    <li>양액공급시스템, 양액 교반기</li>
                    <li>무인방제시스템 (이류체 저압 포그시스템, 노즐 및 배관)</li>
                    <li>결로 모니터링 시스템 (모니터링 경보기, 센서노드)</li>
                </ul>
                <p>연락처 010-7533-0772 또는 이메일 info@hdeng.net</p>
            </Row>
        </Container>
    )
}