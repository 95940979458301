import React from 'react';
import './App.css';
// Library Import
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom'
import { useState } from 'react';
// Project Page Import(라우팅 할땐 대문자로 시작하기)
import Main from './Main/Main.js'
import Navi from './Main/Navi.js'
import Footer from './Main/Footer.js'
import Company from './Main/Company.js'
import Product from './Product/Product.js'
import Hdro from './Product/hdro/hdro.js'
import Case from './Product/hdro/Case.js'
// import FloateDB from './FloateDB.js'
// Bootstrap Library
import { Container, Row, Col } from 'react-bootstrap';
//import Dbtest from './dbtest.js';
// import axios from 'axios';
import RouteChangeTracker from './RouteChangeTracker.js';
import Page404 from './Page404';
import Estimate from './Main/Estimate';
import Estimate2 from './Main/Estimate2';
//import Estimate2 from './Main/Estimate2';
import Notice from './Main/Notice';
// import KakaoRedirectHandler from './KakaoApi.js'
// import { BrowserRouter as Router, Switch } from "react-router-dom";
import Auth from "./Auth.js";
import axios from 'axios';

function App() {
  // 방문자수 카운트
  axios.get('https://api.hdeng.net/visitor')
  RouteChangeTracker();

  return (

    <Container className='App' fluid='xxl'>
      <Row>
        {/* Navigation */}
        {Navi()}
      </Row>
      <Row>
        {/* Router */}
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/company" element={<Company />} />
          <Route path="/product" element={<Product />} />
          <Route path="/kakao" element={<Estimate />} />
          {/* <Route path="/estimate" element={<Estimate />} /> */}
          <Route path="/estimate2" element={<Estimate2 />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/hdro" element={<Hdro />} />
          <Route path="/hdro/case" element={<Case />} />
          <Route path="*" element={<Page404 />} />
          <Route path="/oauth/kakao/callback" component={Auth} />
          {/* <Route path="/login" component={KakaoRedirectHandler} /> */}
        </Routes>
      </Row>
      <Row>
        {/* Footer */}
        <Footer />
      </Row>
    </Container>
  );
}

export default App;


