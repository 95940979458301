import React, { useState } from 'react';
import './hdro.css';
// Bootstrap
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

function hdro() {

    return (
        <Container className='main' fluid="lg">
            <Row>
            </Row>
            <Row>
                <Col>{Discription()}</Col>
                {/* {Discription()} */}
            </Row>
        </Container>
    )
}
function Discription() {
    const REST_API_KEY = "77a588b1d637314c2444080fa23aa56e";
    const REDIRECT_URI = "http://0772.iptime.org:3000/kakao";
    // const REDIRECT_URI = "http://localhost:3000/oauth/kakao/callback";
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

    return (
        <div>
            <h3 className='title'>농업용수 처리기 (역삼투압)</h3>
            <Image className='img-product' src="https://hdeng13.speedgabia.com/hdro/model/hdro801/bc-hdro801.png" rounded />
            <h3 className='title'>정수의 원리와 응용분야</h3>
            < ROProcess />
            <h3 className='title'>주요 특징 및 특장점</h3>
            <ul className='list-spec'>
                <li>사용 조건에 따라 사용량, 수질 상황에 맞춤 설계</li>
                <li>시스템 프레임 적용으로 향후 정수 용량 추가 가능</li>
                <li>컴팩트한 디자인으로 유지보수의 편의성</li>
                <li>고압 접액부 스테인레스 또는 고압 배관 사용으로 내구성 증대</li>
                <li>터치 스크린 컨트롤러로 작동 상황 실시간 모니터링 및 제어 가능</li>
                <li>전자동 수위 제어 시스템 및 경보 기능</li>
                <li>정수 + 원수 희석 제어 가능</li>
                <li>품질 보증 및 신속한 A/S 대응</li>
                {/* <li></li>
                <li></li>
                <li></li> */}
            </ul>
            <h3 className='title'>모델별 제원표</h3>
            <Model />
            <h3 className='title'>모델별 세부 사양</h3>
            <ModelAccordion />
            <h3 className='title'>제품 외형도</h3>
            <Image src="https://hdeng13.speedgabia.com/hdro/drawing/cad-hdro801.png" rounded />
            <h3 className='title'>공정흐름도<br />(Process Flow Diagram)</h3>
            <h4>모델 HDRO-402 (생산량 0.5톤/시간)</h4>
            <Image src="https://hdeng13.speedgabia.com/hdro/drawing/hdro402.jpg" rounded />
            <h4>모델 HDRO-801 (생산량 1톤/시간)</h4>
            <Image src="https://hdeng13.speedgabia.com/hdro/drawing/hdro801.jpg" rounded />
            <h3 className='title'>주요 부품 구성</h3>
            <Parts />
            <h3 className='title'>필터 구성</h3>
            <Filters />
            {/* 전처리 필터 구성에 대한 필요성, 사진 첨부 */}
            <h3 className='title'>컨트롤러 UI</h3>
            <ControlUI />
            {/* UI 특징, 기능, 사진 */}
            <div>

                <Button variant="primary" href={KAKAO_AUTH_URL} style={{ margin: '20px',width:'200px'}}>온라인 견적 바로가기</Button>
            </div>
        </div>
        // 설치 사례 : 적용 모델, 생산량, 설치장소, 세부 설명 큰화면2분할, 작은화면 1분할, 유튜브 링크
    )
}

function ROProcess() {
    return (
        <div className='text-roprocess'>
            <p>반투막 정수는 오염된 물을 반투막에 통과시켜 불순물을 제거하는 공정입니다.
                필터 막은 여과기 역할을 하여 순수한 물은 통과시키고 한쪽에는 오염 물질을 가두어 둡니다.
                이 공정은 역삼투압, 미세여과, 한외여과 또는 나노여과 멤브레인을 사용하여 수행할 수 있으며,
                각각은 특정 오염 물질을 표적으로 삼기 위해 서로 다른 기공 크기를 가지고 있습니다.
                생성된 정제수는 발전소, 식품 및 음료 가공, 제약 생산과 같은 다양한 산업 및 상업 응용 분야에
                적합합니다.</p>
            <p>농업에서 역삼투압 방식의 정수 도입은 다음과 같은 이점이 있습니다.</p>
            <ul>
                <li>관수 : 역삼투압으로 정수한 물은 관수를 위해 깨끗하고 안전한 물을 제공할 수 있습니다.
                    또한 식물 성장 및 질병 확산을 방지하는데 큰 도움을 줍니다.</li>
                <li>축산 : 역삼투압 방식의 정수된 물은 먹는 물 기준을 통과하는 안전한 식수 입니다.</li>
                <li>양액재배 : 항상 균일하고 깨끗히 정수된 물은 양액재배를 위한 최적의 물이며 식물 생장을 개선하는데 도움을 줍니다.</li>
                <li>작물세척 : 정수된 물을 작물 세척에 이용함으로써 오염의 위험을 줄이고 농산물의 안전을 보장하는 데 사용할 수 있습니다.</li>
            </ul>
        </div>
    )
}

function ControlUI() {
    return (
        <div>
            <Image src="https://hdeng13.speedgabia.com/hdro/manual/main-ui.png" className='img-ui' />
            <h4>메인 컨트롤러 화면</h4>
            <ul>
                <li>자동제어 : 수위에 의한 자동 제어, 원수탱크(저수위, 만수위) & 정수탱크(만수위)</li>
                <li>수동제어 : 터치스크린을 이용해 직접 제어 (테스트 운전시 사용)</li>
                <li>실시간 모니터링 : 정수 유량, 퇴수 유량, 물탱크 수위, 원수 및 정수 전도도, 펌프 가동 상태</li>
                <li>원격 모니터링 및 제어 : 인터넷 연결 시 스마트폰 연동</li>
                {/* <li>데이터 로그 : 인터넷 연결 시 서버로 데이터 저장, 웹으로 데이터 확인</li> */}
            </ul>
            <Image src="https://hdeng13.speedgabia.com/hdro/manual/set-ui.png" className='img-ui' />
            <h4>운전 설정 화면</h4>
            <ul>
                <li>펌프 작동 지연시간 설정 : 수위 센서 헌팅으로 인한 빈번한 동작 방지</li>
                <li>전자식 유량 센서 보정으로 정확한 모니터링</li>
                <li>현장 상황에 따라 인버터 제어를 통해 펌프 안정성 향상</li>
            </ul>
        </div>
    )
}

function Model() {

    let x = useSelector((state) => state.hdroModel)

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>모델명<br />(Model)</th>
                    <th>생산량<br />(톤 / 시간)</th>
                    <th>전기사양<br />(V / Hz)</th>
                    <th>펌프용량<br />(마력 / kW)</th>
                    {/* <th>멤브레인/수량<br />(Module / EA)</th> */}
                    {/* <th>제품크기/중량<br />(mm / Kg)</th> */}
                </tr>
            </thead>
            <tbody>
                {
                    x.map((a, i) =>
                        <tr key={i}>
                            <td>{x[i].name}</td>
                            <td>{x[i].capacity}</td>
                            <td>{x[i].electric}</td>
                            <td>{x[i].pump}</td>
                            {/* <td>{x[i].filter}</td> */}
                            {/* <td>{x[i].size}</td> */}
                        </tr>
                    )
                }
            </tbody>
        </Table>
    )
}
function Parts() {

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>사진</th>
                    <th>구성</th>
                    <th>상세</th>
                    <th>규격</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><Image className='img-parts' src="https://hdeng13.speedgabia.com/common/noImg_M.png" rounded /></td>
                    <td>시스템 프레임</td>
                    <td>스틸분체도장</td>
                    <td>SIZE 760x510x1700</td>
                </tr>
                <tr>
                    <td><Image className='img-parts' src="https://hdeng13.speedgabia.com/common/noImg_M.png" rounded /></td>
                    <td>컨트롤러<br />(수위조절 및 모니터링)</td>
                    <td>터치컨트롤러<br />산업용PLC</td>
                    <td>10" TouchScreen<br />Custom PLC</td>
                </tr>
                <tr>
                    <td><Image className='img-parts' src="https://hdeng13.speedgabia.com/hdro/parts/2018-04-15 18;22;47.jpg" rounded /></td>
                    <td>고압 펌프<br />(모델에 맞게 적용)</td>
                    <td>횡형다단펌프<br />입형다단펌프</td>
                    <td>1.3kW/220V/60Hz<br />4.0kW/380V/60Hz</td>
                </tr>
                <tr>
                    <td><Image className='img-parts' src="https://hdeng13.speedgabia.com/hdro/parts/IMG_0033.JPG" rounded /></td>
                    <td>멤브레인 필터 하우징<br />& 멤브레인 필터</td>
                    <td>FRP 하우징(300psi)</td>
                    <td>4" 또는 8"</td>
                </tr>
                <tr>
                    <td><Image className='img-parts' src="https://hdeng13.speedgabia.com/hdro/parts/IMG_0032.JPG" rounded /></td>
                    <td>마이크로 필터 하우징<br />& 마이크로 필터</td>
                    <td>PVC 하우징<br />PP 새디먼트</td>
                    <td>20" / 20A / 2EA<br />20" / 1 micron</td>
                </tr>
                <tr>
                    <td><Image className='img-parts' src="https://hdeng13.speedgabia.com/common/noImg_M.png" rounded /></td>
                    <td>계측기</td>
                    <td>압력 게이지<br />유량 게이지<br />유량 센서<br />전도도 측정기</td>
                    <td>60Ø 10Bar<br />아크릴 25A<br />전자식 25A<br />0~2000㎲/㎝</td>
                </tr>
                <tr>
                    <td><Image className='img-parts' src="https://hdeng13.speedgabia.com/hdro/parts/inlet.png" rounded /></td>
                    <td>원수 탱크</td>
                    <td>PVC 물탱크</td>
                    <td>원형 400L</td>
                </tr>
            </tbody>
        </Table>
    )
}
function Filters() {

    return (
        <Table striped bordered hover size="sm">
            <colgroup>
                <col width="30%" />
                <col width="70%" />
                {/* <col width="50%" /> */}
            </colgroup>
            {/* <thead>
                <tr>
                    <th>사진</th>
                    <th>구성(규격)</th>
                    <th>상세</th>
                </tr>
            </thead> */}
            <tbody>
                <tr>
                    <td>
                        <tr><Image className='img-filter' src="https://hdeng13.speedgabia.com/common/noImg_M.png" rounded /></tr>
                        <tr>멤브레인 필터<br />4040 / 8040</tr>
                    </td>
                    <td>역삼투압 멤브레인 필터는 초미세 막을 역삼투압을 이용해 대부분(96%이상)의 오염물질, 세균, 이온 등을 제거합니다.
                        염분 농도에 따라 일반용과 저압용, 해수용으로 구분하여 사용합니다. 일반적으로 생산량이 많은 저압용을 사용합니다.
                        생산수량은 필터의 구성, 운전 압력, 수온, 수질 상태에 영향을 받습니다.
                        필터의 교체 주기는 운전 환경 및 수질에 따라 크게 차이가 날 수 있어 예측하긴 어렵지만 1년 이상 사용을 목적으로 합니다.
                    </td>
                </tr>
                <tr>
                    <td>
                        <tr><Image className='img-filter' src="https://hdeng13.speedgabia.com/common/noImg_M.png" rounded /></tr>
                        <tr>마이크로필터<br />PP 1 micron 20"</tr>
                    </td>
                    <td>정수 설비 기본 구성으로 마이크로 필터는 역삼투압 공정 전단에 설치하여 멤브레인 필터를 보호합니다.
                        1 micron 이상의 고형물질을 제거하며, 정수 효율이 우수하고 교체가 용이하여 관리성이 좋습니다.</td>
                </tr>
                <tr>
                    <td>
                        <tr><Image className='img-filter' src="https://hdeng13.speedgabia.com/common/noImg_M.png" rounded /></tr>
                        <tr>샌드필터<br />FRP Tank & Control Head</tr>
                    </td>
                    <td> 탁도가 높고 용액에 고형물이 많을 경우 샌드필터를 선택적으로 적용합니다. FRP Tank 내부에 여과사 또는 전용 여재를 충전해 이를 통과함으로써
                        급속 여과가 가능합니다. 멤브레인 필터 및 마이크로 필터의 수명을 비약적으로 늘려주며,
                        컨트롤 밸브의 타이머 기능을 이용해 자동 역세척 함으로 유지 비용이 크게 절감됩니다.
                        여재의 교체 시기는 내부 스트레이너의 막힘으로 인한 유량 감소, 여재의 물리적 손상으로 여과 효율이 감소될 때 입니다.
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}

function ModelAccordion() {
    let x = useSelector((state) => state.hdroModel)

    return (

        <> {x.map((a, i) =>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey={x[i].value}>
                    <Accordion.Header>{x[i].name} (생산량 {x[i].capacity} m³/시간)</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>사이즈(mm) / 무게(kg) : {x[i].size}</li>
                            <li>멤브레인 모델 / 수량 (개) : {x[i].filter}</li>
                        </ul>

                        <img
                            className="d-block w-100"
                            src={x[i].img}
                            alt="Second slide"
                        />
                        {/* <h3>제품 사진</h3> */}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )}</>
    )
}

function productCarousel() {
    return (
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="./img/Product/hdro803.png"
                    alt="First slide"
                />
                <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="./img/Product/hdro803.png"
                    alt="Second slide"
                />

                <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="./img/Product/hdro803.png"
                    alt="Third slide"
                />

                <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}
function productList() {
    return (
        <div>
            <h2>농업용수 처리기</h2>
            <ListGroup>
                <ListGroup.Item>HDRO-401 (5톤/일)</ListGroup.Item>
                <ListGroup.Item>HDRO-402 (10톤/일)</ListGroup.Item>
                <ListGroup.Item>HDRO-801 (20톤/일)</ListGroup.Item>
                <ListGroup.Item>HDRO-802 (40톤/일)</ListGroup.Item>
                <ListGroup.Item>HDRO-803 (60톤/일)</ListGroup.Item>
            </ListGroup>
        </div>
    )
}

export default hdro;