import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
// import Button from 'react-bootstrap/Button';
// import axios from 'axios';
import './Estimate'
import './Estimate2.css';
import makePdf from '../make_pdf';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
//customer: customer, mainItem: mainItem, subItem: subItem main_item
// const { customer, mainItem, subItem } = useLocation();
import Button from 'react-bootstrap/Button';

//숫자를 한글로 변환 https://sub0709.tistory.com/93
function num2han(num) {
    num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';  // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
    if (num == '0')
        return '영';
    var number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
    var unit = ['', '만', '억', '조'];
    var smallUnit = ['천', '백', '십', ''];
    var result = [];  //변환된 값을 저장할 배열
    var unitCnt = Math.ceil(num.length / 4);  //단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.
    num = num.padStart(unitCnt * 4, '0')  //4자리 값이 되도록 0을 채운다
    var regexp = /[\w\W]{4}/g;  //4자리 단위로 숫자 분리
    var array = num.match(regexp);
    //낮은 자릿수에서 높은 자릿수 순으로 값을 만든다(그래야 자릿수 계산이 편하다)
    for (var i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
        var hanValue = _makeHan(array[i]);  //한글로 변환된 숫자
        if (hanValue == '')  //값이 없을땐 해당 단위의 값이 모두 0이란 뜻. 
            continue;
        result.unshift(hanValue + unit[unitCnt]);  //unshift는 항상 배열의 앞에 넣는다.
    }
    //여기로 들어오는 값은 무조건 네자리이다. 1234 -> 일천이백삼십사
    function _makeHan(text) {
        var str = '';
        for (var i = 0; i < text.length; i++) {
            var num = text[i];
            if (num == '0')  //0은 읽지 않는다
                continue;
            str += number[num] + smallUnit[i];
        }
        return str;
    }
    return result.join('');
}

export default function Estimate2(props) {
    const { state } = useLocation();
    console.log(state);
    let x = useSelector((state) => state.hdroModel)
    let x2 = useSelector((state) => state.hdroPreFilter)

    // const [_mainItem, _setMainItem] = useState(
    //     {
    //         id: 0,
    //         name: x[props.mainItem].name,
    //         specific: x[props.mainItem].capacity,
    //         price: x[props.mainItem].price,
    //         vat: (x[props.mainItem].price * 0.1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    //     }
    // )
    const [_mainItem, _setMainItem] = useState(
        {
            id: 0,
            name: x[state.main].name,
            specific: x[state.main].capacity,
            price: x[state.main].price,
            vat: (x[state.main].price * 0.1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        }
    )

    // console.log('msg:: ' + _mainItem)
    console.log(props.subItem)

    const _subItem = [];

    //const sub = props.subItem;    //origin
    const sub = state.sub;      //new
    console.log('sub:: ' + sub)

    let subPrice = 0;

    for (let i = 0; i < sub.length; i++) {
        subPrice += x2[sub[i]].price
        _subItem.push(
            {
                id: 'id' + sub[i],
                name: x2[sub[i]].name,
                specific: x2[sub[i]].capacity,
                price: x2[sub[i]].price,
                vat: x2[sub[i]].price * 0.1
            }
        );
    }

    var totalPrice = (_mainItem.price + subPrice).toLocaleString();
    var totalVat = ((_mainItem.price + subPrice) * 0.1).toFixed(0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    var total = (((_mainItem.price + subPrice) * 1.1).toFixed(0)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

    var totali = ((_mainItem.price + subPrice) * 1.1).toFixed(0);
    var totalHan = num2han(totali);

    let today = new Date();
    today = today.toLocaleDateString();

    const navigate = useNavigate();

    const onClick = async (e) => {
        const pdf = makePdf();
        e.preventDefault();
        await pdf.viewWithPdf();
    }

    return (
        <>
            <div style={{ display: 'block', margin: '10px' }}>
                <Button className='est2-btn' variant="primary" onClick={onClick}>PDF 다운로드</Button>
                <Button className='est2-btn' variant="primary" onClick={() => { navigate(-1); }}>뒤로가기</Button>
            </div>

            <Container className="container-a4" fluid>
                <Row className="row1">
                    <h1>견ㅤㅤ적ㅤㅤ서</h1>
                </Row>
                <Row className="row2">
                    <Col xs={4} className="info-customer">
                        {/* <p>수ㅤ신 : {props.customer.name} 귀하</p>
                    <p>연락처 : {props.customer.phone}</p> */}
                        <p>수ㅤ신 : {state.name} 귀하</p>
                        <p>연락처 : {state.phone}</p>
                        <p>작성일 : {today}</p>
                        <p>모델명 : {_mainItem.name}</p>
                        <p>생산량 : {_mainItem.specific} m³/h</p>
                    </Col>
                    <Col xs={8}>
                        <table className="info-company">
                            <tr>
                                <td colSpan={2}>사업자 등록번호</td>
                                <td colSpan={2}>143-81-11889</td>
                            </tr>
                            <tr>
                                <td >상 호</td>
                                <td >한들이엔지(주)</td>
                                <td >대 표</td>
                                <td >김 주 환</td>
                            </tr>
                            <tr>
                                <td colSpan={1}>주 소</td>
                                <td colSpan={3}>경기도 안산시 산단로 325, F524호</td>
                            </tr>
                            <tr>
                                <td >전 화</td>
                                <td >031-356-3752</td>
                                <td >팩 스</td>
                                <td >02-6918-6504</td>
                            </tr>
                            <tr>
                                <td colSpan={1}>이메일</td>
                                <td colSpan={3}>info@hdeng.net</td>
                            </tr>
                        </table>
                    </Col>
                </Row>

                <Row className="row3">
                    <h5>견적 금액 : {totalHan} 원(VAT 포함)</h5>
                    <Table responsive bordered className="table-contents">
                        <thead>
                            <th>구 분</th>
                            <th>품 목</th>
                            <th>규 격</th>
                            <th>수 량</th>
                            <th>단 가</th>
                            <th>금 액</th>
                            <th>VAT</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>모 델</td>
                                <td>{_mainItem.name}</td>
                                <td>{_mainItem.specific} m³/h</td>
                                <td>1</td>
                                <td style={{ textAlign: 'right' }}>{_mainItem.price.toLocaleString()}</td>
                                <td style={{ textAlign: 'right' }}>{_mainItem.price.toLocaleString()}</td>
                                <td style={{ textAlign: 'right' }}>{_mainItem.vat.toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>멤브레인 필터</td>
                                {/* <td>{x[props.mainItem].filter}</td>
                            <td>{x[props.mainItem].filterQty}</td> */}
                                <td>{x[state.main].filter}</td>
                                <td>{x[state.main].filterQty}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>고압 다단 펌프</td>
                                {/* <td>{x[props.mainItem].pump} kW</td> */}
                                <td>{x[state.main].pump} kW</td>
                                <td>1</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>마이크로 필터</td>
                                <td>20인치</td>
                                <td>2</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>터치 컨트롤러</td>
                                <td>10인치</td>
                                <td>1</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>시스템 프레임</td>
                                {/* <td>{x[props.mainItem].size}</td> */}
                                <td>{x[state.main].size}</td>
                                <td>1</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>계측기</td>
                                <td>EC, 압력, 유량</td>
                                <td>2</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>PVC 물탱크</td>
                                <td>400L 원형</td>
                                <td>1</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {
                                _subItem.map((sub, i) => (
                                    <tr key={i}>
                                        <td>추가설비{i + 1}</td>
                                        <td>{sub.name}</td>
                                        <td>{sub.specific}</td>
                                        <td>1</td>
                                        <td style={{ textAlign: 'right' }}>{sub.price.toLocaleString()}</td>
                                        <td style={{ textAlign: 'right' }}>{sub.price.toLocaleString()}</td>
                                        <td style={{ textAlign: 'right' }}>{sub.vat.toLocaleString()}</td>
                                    </tr>
                                ))
                            }
                            <tr className='tr1'>
                                <td >견적유효</td>
                                <td colSpan={2}>견적일로부터 30일</td>
                                <td colSpan={2}>공급가 합계</td>
                                <td style={{ textAlign: 'right' }} colSpan={2}>{totalPrice}</td>
                            </tr>
                            <tr>
                                <td >지불조건</td>
                                <td colSpan={2}>계약금 50%, 공사완료후 50%</td>
                                <td colSpan={2}>부가세 합계</td>
                                <td style={{ textAlign: 'right' }} colSpan={2}>{totalVat}</td>
                            </tr>
                            <tr>
                                <td >입금계좌</td>
                                <td colSpan={2}>농협 317-0006-3043-91</td>
                                <td colSpan={2}>총금액 합계</td>
                                <td style={{ textAlign: 'right' }} colSpan={2}>{total}</td>
                            </tr>
                            <tr>
                                <td>비 고</td>
                                <td colSpan={6}>
                                    <ul>
                                        <li>역삼투압 정수장치의 견적서 입니다. 설치비포함 금액의 견적입니다.<br />
                                            (도서산간해외는 설치 비용이 추가됩니다.) </li>
                                        <li>1년무상AS(소비자과실, 소모품 제외), 1년 경과 후 유상 AS</li>
                                        <li>납기는 발주일로부터 약 7~15일 소요됩니다.</li>
                                        <li>생산량에 대한 가동조건은 하루 24시간기준입니다. 수질 기준은 정수 후 EC 0.1ms 이하</li>
                                        <li>원수(지하수)투입, 전기인입, 보온시설, 정수 후 저장탱크는 별도이며 현장 구비대상입니다.</li>
                                        <li>정수를 생산하기 위해 퇴수가 발생되며 정수와의 퇴수의 비율을 1:2입니다. (정수1 : 퇴수2)</li>
                                        <li>향후 수질 상황에 따라 샌드필터, 활성탄, 연수기 등 전처리가 추가될 수 있습니다.<br />
                                            (설치 비용은 발주자부담)</li>
                                        <li>본 장비의 문제 발생 시 장비에 대한 책임 외 발생하는 피해는 당사에서 책임지지 않으니<br />
                                            충분히 고려하여 주십시오. (ex,작물생육,생물,퇴수발생 민원사항)</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    )
}